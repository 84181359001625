<template>
  <v-alert
    dense
    text
    type='info'
  >
    <ul>
      <li>
        All template questions are optional to answer. You can edit any of your answers when running a quote in the rater. <br/>
      </li>
      <li>
        Some questions may not apply to all states and carriers.
      </li>
    </ul>
  </v-alert>
</template>

<script>
export default {}
</script>

<style lang='scss' scoped>

</style>
