<template>
  <div>
    <div id='template-title-container' v-if='!loading.nextIndex'>
      <h3 id='template-title-text'>
        <span v-if='!showEditTitle'>{{ template.title }}</span>
        <v-text-field style='font-size:20px' v-model='template.title' outlined hide-details v-if='showEditTitle' placeholder='Enter a template title'
                      @blur='hideEditTitle'></v-text-field>
        <v-btn @click.prevent='showEditTitle = !showEditTitle' icon plain v-if='!showEditTitle'>
          <v-icon>{{ icons.mdiPencil }}</v-icon>
        </v-btn>
      </h3>
    </div>

    <disclaimer class='my-2'></disclaimer>

    <div id='template-questions-container' v-if='!loading.questions'>
      <div v-for='section in questions' :key='section.name' v-show='section.page === page'>
        <h4 class='section-label'>{{ section.name }}</h4>

        <div v-for='question in section.questions' :key='question.key'>
          <div class='d-flex'>
            <div class='flex-grow-1 question-label'>
              <div class='required-in-rater-label' v-if='question.rater_required'>
                Required in Rater
              </div>
              <div>{{ question.label }}</div>
            </div>
            <div class='flex-shrink-1 text-end'>

              <field-single-select
                v-if='question.type === "select"'
                :question='question'
                v-model='responses[question.key]'
              />

              <field-boolean
                v-if='question.type === "boolean"'
                :question='question'
                v-model='responses[question.key]'
              />

              <field-number
                number-type='integer'
                v-if='question.type === "integer"'
                :question='question'
                v-model='responses[question.key]'
              />

              <field-number
                number-type='decimal'
                v-if='question.type === "decimal"'
                :question='question'
                v-model='responses[question.key]'
              />

              <field-text
                v-if='question.type === "text"'
                :question='question'
                v-model='responses[question.key]'
              />

              <field-multiple-select
                v-if='question.type === "multiple_select"'
                :question='question'
                v-model='responses[question.key]'
              />

            </div>
          </div>
        </div>
      </div>
    </div>

    <div id='template-actions-footer-container' v-if='!loading.questions'>
      <div class='d-flex'>
        <div class='flex-grow-1'>

        </div>
        <div class='flex-grow-1 text-center' id='question-pagination-text'>
          PAGE {{ page }} OF {{ lastPage }}
        </div>
        <div class='flex-grow-1 text-end'>
          <v-btn :to="{name: 'rater-templates'}" color='primary' text plain v-if='page === 1'>
            Cancel
          </v-btn>
          <v-btn @click.prevent='goToPage(page - 1)' color='primary' text plain v-else>Back</v-btn>


          <v-btn color='primary' class='ml-2' v-if='page < lastPage'
                 @click.prevent='goToPage(page + 1)'>
            Continue
          </v-btn>
          <v-btn color='primary' class='ml-2' v-else @click.prevent='save'>Save Template</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldText from '@/pages/rater/templates/fields/FieldText.vue'
import FieldNumber from '@/pages/rater/templates/fields/FieldNumber.vue'
import FieldSingleSelect from '@/pages/rater/templates/fields/FieldSingleSelect.vue'
import FieldMultipleSelect from '@/pages/rater/templates/fields/FieldMultipleSelect.vue'
import FieldBoolean from '@/pages/rater/templates/fields/FieldBoolean.vue'
import HasFormErrors from '@/config/mixins/HasFormErrors'
import { mdiPencil } from '@mdi/js'
import Disclaimer from '@/pages/rater/templates/components/Disclaimer.vue'

export default {
  components: { Disclaimer, FieldSingleSelect, FieldMultipleSelect, FieldText, FieldNumber, FieldBoolean },
  mixins: [HasFormErrors],
  data() {
    return {
      icons: {
        mdiPencil,
      },
      originalTemplate: {},
      template: {
        title: '',
      },
      loading: {
        template: true,
        questions: true,
      },
      responses: {},
      page: 1,
      questions: [],
      showEditTitle: false,
    }
  },
  methods: {
    loadRaterTemplate() {
      this.$api.raterTemplates.get({
        templateId: this.$route.params.template,
      }).then((response) => {
        this.originalTemplate = response.data
        this.template.title = response.data.attributes.title
        this.loading.template = false
        this.loadTemplateQuestions()
      }).catch((error) => {
        console.log(error)
        this.loading.template = false
      })
    },
    hideEditTitle(){
      if(this.template.title.length){
        this.showEditTitle = false

        this.save(false)
      }else{
        this.template.title = this.originalTemplate.attributes.title
        this.showEditTitle = false
      }
    },
    loadTemplateQuestions() {
      let lob = this.originalTemplate.related.policy_type.data.attributes.lob_code
      this.$api.raterTemplates.getQuestionsAvailable({
        lob: lob,
      }).then((response) => {
        this.questions = response.data

        this.buildResponseModel()

        this.loading.questions = false
      }).catch((error) => {
        console.log(error)
        this.loading.questions = false
      })
    },
    buildResponseModel() {
      this.$_.forEach(this.questions, (section) => {
        this.$_.forEach(section.questions, (question) => {

          if (this.$_.has(this.originalTemplate.attributes.fields, question.key)) {
            this.$set(this.responses, question.key, this.originalTemplate.attributes.fields[question.key])
            return
          }

          this.$set(this.responses, question.key, null)
        })
      })
    },
    goToPage(page) {
      //change page
      this.page = page

      //scroll to top
      window.scrollTo(0, 0)
    },
    save(redirect = true) {
      this.$api.raterTemplates.update({
          templateId: this.$route.params.template,
          data: {
            title: this.template.title,
            fields: this.responses,
          },
        },
      ).then((response) => {
        if(redirect) {
          this.$router.push({
            name: 'rater-templates',
          })
        }
      }).catch(validationException => {
        this.setFormException(validationException)
      })
    },
  },
  mounted() {
    this.loadRaterTemplate()
  },
  computed: {
    lastPage() {
      if (this.loading.questions) {
        return 1
      }

      return this.$_.maxBy(this.questions, (section) => {
        return section.page
      })?.page ?? 1
    },
  },
}
</script>

<style lang='scss' scoped>
#template-title-container {
  background: #fff;
  padding: 16px;

  #template-title-text {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
  }

  #template-title-description {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}

#template-questions-container {
  margin-top: 8px;
  background: #fff;
  padding: 16px;

  .required-in-rater-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;

  }

  .section-label {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    margin-bottom: 24px;
  }

  .question-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}

#template-actions-footer-container {
  margin-top: 8px;
  background: #fff;
  padding: 16px;

  #question-pagination-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    padding-top: 8px;
    color: #000
  }
}
</style>
