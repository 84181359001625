<template>
  <v-text-field
    outlined
    dense
    style='width:300px'
    :placeholder='placeholder'
    @change="$emit('input', $event)"
    clearable
    :value='value'
  ></v-text-field>
</template>
<script>
export default {
  props: {
    value:{
      type: String | null,
      required: true
    },
    question: {
      type: Object,
      required: true,
    },
    numberType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    placeholder() {
      return this.question.placeholder || 'Enter a number'
    },
  },
}
</script>
