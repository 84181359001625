<template>
  <v-select
    :items="[{text:'Yes', value: true},{text: 'No', value: false}]"
    item-text='text'
    item-value='value'
    outlined
    dense
    :placeholder='placeholder'
    style='width:300px;'
    @change="$emit('input', $event)"
    clearable
    :value='value'
  >
  </v-select>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean | null,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    placeholder() {
      return this.question.placeholder || 'Please select'
    },
  },
}
</script>
