<template>
    <v-select
      :items='question.options'
      item-text='label'
      item-value='key'
      outlined
      dense
      :placeholder='placeholder'
      style='width:300px;'
      @change="$emit('input', $event)"
      clearable
      :value='value'
    >
    </v-select>
</template>
<script>
export default {
  props: {
    value: {
      type: String | null,
      required: true,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    placeholder() {
      return this.question.placeholder || 'Please select'
    },
  },
}
</script>
