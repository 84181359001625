<template>
  <v-text-field
    outlined
    dense
    :placeholder='placeholder'
    style='width:300px;'
    @change="$emit('input', $event)"

  ></v-text-field>
</template>
<script>
export default {
  props: {
    value:{
      type: String,
      required: true
    },
    question:{
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {

  },
  computed: {
    placeholder() {
      return this.question.placeholder || 'Please enter a value'
    },
  },
}
</script>
